<template>
  <div class="container-fluid round px-0 mb-5">
    <div class="page-header mb-1 d-flex justify-content-between">
      <strong>ตรวจสอบคิวโพย</strong>
      <button class="btn p-0 btn-link" @click="reload">reload</button>
    </div>
    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th width="80" class="text-center">#</th>
            <th class="text-center">Instance ID</th>
            <th
              width="150"
              class="text-center pointer"
              @click="toggleSort('queue')"
            >
              <button class="btn p-0" :class="[{'font-weight-bold': sortBy==='queue'}]">Queue</button>
                <i v-if="sortBy==='queue' && sortOrder==='asc'" class="fas fa-sort-down text-primary"></i>
                <i v-if="sortBy==='queue' && sortOrder==='desc'" class="fas fa-sort-up text-primary"></i>
            </th>
            <th
              width="100"
              class="text-center pointer"
              @click="toggleSort('isRunning')"
            >
              <button class="btn p-0" :class="[{'font-weight-bold': sortBy==='isRunning'}]">Status</button>
              <i v-if="sortBy==='isRunning' && sortOrder==='asc'" class="fas fa-sort-down text-primary"></i>
              <i v-if="sortBy==='isRunning' && sortOrder==='desc'" class="fas fa-sort-up text-primary"></i>
            </th>
            <th
              width="300"
              class="text-center pointer"
              @click="toggleSort('lastActive')"
            >
              <button class="btn p-0" :class="[{'font-weight-bold': sortBy==='lastActive'}]">Last Active</button>
              <i v-if="sortBy==='lastActive' && sortOrder==='asc'" class="fas fa-sort-down text-primary"></i>
              <i v-if="sortBy==='lastActive' && sortOrder==='desc'" class="fas fa-sort-up text-primary"></i>
            </th>
            <th width="200" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ item.instanceId }}</td>
            <td class="text-center">{{ item.queue }}</td>
            <td class="text-center"><i class="fas fa-circle" :class="[{'text-success': item.isRunning}, {'text-danger': !item.isRunning}]"></i></td>
            <td class="text-center">{{ item.lastActive | dispDateTime('DD/MM/YYYY HH:mm:ss') }}</td>
            <td class="text-center">
              <button class="btn mx-1 btn-sm btn-success" title="start" @click="startInstance(item.instanceId)"><i class="fas fa-play"></i></button>
              <button class="btn mx-1 btn-sm btn-warning" title="restart" @click="restartInstance(item.instanceId)"><i class="fas fa-redo"></i></button>
              <button class="btn mx-1 btn-sm btn-danger" title="stop" @click="stopQueues(item.instanceId)"><i class="fas fa-stop"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import QueueService from '@/services/queueService'
import _ from 'lodash'
import Swal from 'sweetalert2'

export default {
  name: 'TicketQueues',
  data() {
    return {
      data: null,
      waitData: null,
      sortBy: 'lastActive',
      sortOrder: 'asc'
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return _.sortBy(this.data.map((item)=>{
        item.lastActive = new Date(item.lastActive)
        item.queue = this.waitData?.[item.instanceId] || 0
        return item
      }), [(item)=>{
        return {
          asc: item[this.sortBy],
          desc: -item[this.sortBy]
        }[this.sortOrder]
      }])
    }
  },
  methods: {
    getDatas() {
      LottoService.getTicketQueue()
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          const instanceIds = (this.data||[]).map((item)=>{
            return item.instanceId
          })

          if(instanceIds.length) {
            this.getWaiting(instanceIds)
          }
        }
      })
    },
    startInstance(instanceId) {
      QueueService.startInstance(instanceId)
      .then((response)=>{
        if(response.success) {
          this.reload()
        }
      })
    },
    stopInstance(instanceId) {
      QueueService.stopInstance(instanceId)
      .then((response)=>{
        if(response.success) {
          this.reload()
        }
      })
    },
    restartInstance(instanceId) {
      QueueService.stopInstance(instanceId)
      .then((response)=>{
        if(response.success) {
          QueueService.startInstance(instanceId)
          .then((response)=>{
            if(response.success) {
              this.reload()
            }
          })
        }
      })
    },
    getWaiting(instanceIds) {
      QueueService.getWaiting(instanceIds)
      .then((response)=>{
        if(response.success) {
          this.waitData = response.data
        }
      })
    },
    stopQueues(instanceId) {
      Swal.fire({
        title: 'ต้องการยกเลิกคิวโพยทั้งหมด',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          QueueService.stopQueues(instanceId)
          .then((response)=>{
            if(response.success) {
              return response?.data
            }else{
              throw new Error(response?.data)
            }
          })
          .catch((error)=>{
            Swal.showValidationMessage(error.message)
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success'
          })
          .then(()=>{
            this.reload()
          })
        }
      })
    },
    toggleSort(col) {
      this.sortBy = col
      this.sortOrder = this.sortOrder==='asc' ? 'desc' : 'asc'
    },
    reload() {
      this.getDatas()
    }
  },
  mounted() {
    this.reload()
  }
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.table {
  thead {
    tr {
      th {
        font-weight: normal;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
